import axios from "../util/axios";

export const getUsers = async (status, search, currentPage) => {
  try {
    const perPageContent = 10;
    const offset = (+currentPage - 1) * +perPageContent;
    const params = {
      offset,
      limit: perPageContent,
      sort: JSON.stringify([["created_at", "desc"]]),
    };
    const whereCondition = {
      role: "User",
    };
    if (search !== "") {
      params.search = search;
    }
    if (status !== null) {
      whereCondition.active = status;
    }
    params.where = JSON.stringify(whereCondition);
    const response = await axios.get("/user", { params });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const getUser = async (id) => {
  try {
    const response = await axios.get(`/user/${id}`);
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      throw new Error("404");
    } else {
      throw new Error(error);
    }
  }
};

export const putUser = async (id, data) => {
  try {
    const response = await axios.put(`/user/${id}`, { active: data });

    return response?.data?.data?.user;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};
