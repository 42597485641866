import Grid from "@mui/material/Grid";

import AvatarImage from "assets/images/no-image.jpg";
import MDBox from "components/MDBox";
import DashboardNavbar from "components/Navbar";
// Data
import ContentLayout from "layouts/content";

import Divider from "@mui/material/Divider";
// @mui icons

import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

import Card from "@mui/material/Card";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Loading from "components/Loading";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, putUser } from "services/users";
import { getDate } from "util/date";
import authorsTableData from "./data/authorsTableData";

function Profile() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [user, setUser] = useState(null);
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { user, payment_history } = await getUser(id);
        setUser(user);
        setHistory(payment_history);
        setLoading(false);
      } catch (error) {
        if (error?.message !== "404") {
          setError(true);
        } else {
          navigate("/not-found");
        }
      }
    };
    fetchData();
  }, []);
  const handleBlock = async () => {
    setIsSubmiting(true);
    const response = await putUser(id, !user?.active);
    if (response) {
      setUser(response);
    }
    setIsSubmiting(false);
    setOpen(false);
  };
  const { columns, rows } = authorsTableData(history);
  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Card
            sx={{
              p: 2,
            }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              p={1}
            >
              <Grid item alignItems="center">
                <Box gap={2} sx={{ display: "flex" }}>
                  <Grid item>
                    <img
                      src={user?.image ? user?.image : AvatarImage}
                      alt="profile-image"
                      style={{
                        objectFit: "cover",
                        width: "5rem",
                        height: "5rem",
                        borderRadius: "50%",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                      <MDTypography
                        variant="subtitle2"
                        fontWeight="medium"
                        sx={{ marginTop: "1rem", marginLeft: "0.5rem" }}
                      >
                        {user?.name}
                      </MDTypography>
                      <MDBadge
                        badgeContent={
                          user?.subscription_status
                            ? "Subscribed"
                            : "Unsubscribed"
                        }
                        color={user?.subscription_status ? "success" : "error"}
                        variant="gradient"
                        size="sm"
                      />
                    </MDBox>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    borderRadius: "25px",
                  }}
                  onClick={() => setOpen(true)}
                >
                  {user?.active ? " Block" : "Unblock"}
                </MDButton>
              </Grid>
            </Grid>

            <MDBox pb={3}>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <ProfileInfoCard
                  title="profile information"
                  info={{
                    firstName: user?.first_name,
                    lastName: user?.last_name,
                    email: user?.email,
                    subscriptionStatus: user?.subscription_status
                      ? "Yes"
                      : "No",
                    nextRenewalDate: user?.renewal_date
                      ? getDate(user?.renewal_date.split("T")[0])
                      : "NA",
                  }}
                  action={{
                    route: "",
                    tooltip: "Edit Profile",
                  }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
            </MDBox>
          </Card>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography variant="h6" color="white">
                          Payment History
                        </MDTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className={"custom_flex"}
                      >
                        {" "}
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {history?.length === 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <MDTypography
                          component="a"
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Oops !! No Result Found
                        </MDTypography>
                      </Box>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Block User</DialogTitle>
            <DialogContent>
              Are you sure you want to {user?.active ? " block" : "unblock"}{" "}
              this user ?
            </DialogContent>
            <DialogActions>
              <MDButton onClick={() => setOpen(false)} color="secondary">
                Cancel
              </MDButton>
              <MDButton
                disabled={isSubmiting}
                color="primary"
                onClick={handleBlock}
              >
                Yes
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </ContentLayout>
  );
}

export default Profile;
