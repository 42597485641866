import { Icon, Menu, MenuItem } from "@mui/material";
import AvatarImage from "assets/images/no-image.jpg";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Fragment, useState } from "react";
import { getDate } from "util/date";
export default function data(users, currentPage, perPageContent) {
  const Author = ({ image, name, href }) => (
    <MDBox
      href={href}
      component="a"
      display="flex"
      alignItems="center"
      lineHeight={1}
    >
      <img
        src={image}
        alt={name}
        style={{
          objectFit: "cover",
          width: "2.2rem",
          height: "2.2rem",
          borderRadius: "50%",
        }}
      />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const ActionItem = () => {
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const renderMenu = (
      <Menu
        id="simple-menu"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Action</MenuItem>
        <MenuItem onClick={closeMenu}>Another action</MenuItem>
        <MenuItem onClick={closeMenu}>Something else</MenuItem>
      </Menu>
    );

    return (
      <Fragment>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </Fragment>
    );
  };

  return {
    columns: [
      { Header: "Sl.No", accessor: "slno", align: "center" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "subscription", accessor: "subscription", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "registered on", accessor: "registeredon", align: "center" },
    ],

    rows: users.map((user, key) => {
      return {
        slno: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {(currentPage - 1) * perPageContent + key + 1}
          </MDTypography>
        ),
        name: (
          <Author
            image={user?.image ? user?.image : AvatarImage}
            name={user?.name}
            href={`/users/${user?.id}`}
          />
        ),
        email: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {user?.email}
          </MDTypography>
        ),

        subscription: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={user?.subscription_date !== null ? "Yes" : "No"}
              color={user?.subscription_date !== null ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={user?.active ? "Active" : "Blocked"}
              color={user?.active ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        registeredon: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {user?.created_at ? getDate(user?.created_at.split("T")[0]) : null}
          </MDTypography>
        ),
      };
    }),
  };
}
